import { StrictMode } from "react";
import { useNavigate, Link } from "react-router-dom";

const Navigation = () => {

    const history = useNavigate();

    function delayAndGo(e, path) {
        e.preventDefault();
        var elem = document.getElementById("transOut");
        elem.classList.add("animateTransition")
        setTimeout(() =>{
            history(path)
            elem.classList.remove("animateTransition")
        }, 1500);
    }

    return( <>
        <StrictMode>
            <section className='cta-wp'>
                <Link className="button-outline button--atlas-outline scrollto" id="footer-cta" to="/works" onClick={(e) => delayAndGo(e, "/works")}>
                    <span>Works</span>
                    <div className="marqueebutton-outline" aria-hidden="true">
                        <div className="marquee__inner-outline">
                        <span>Works</span>
                        <span>Works</span>
                        <span>Works</span>
                        <span>Works</span>
                        <span>Works</span>

                        <span>Works</span>
                        <span>Works</span>
                        <span>Works</span>
                        <span>Works</span>
                        <span>Works</span>
                        </div>
                    </div>
                </Link>
                <a className="button-outline button--atlas-outline js-end scrollto" href="#form">
                    <span>Say Hi!</span>
                    <div className="marqueebutton-outline" aria-hidden="true">
                        <div className="marquee__inner-outline">
                        <span>Say Hi!</span>
                        <span>Say Hi!</span>
                        <span>Say Hi!</span>
                        <span>Say Hi!</span>
                        <span>Say Hi!</span>

                        <span>Say Hi!</span>
                        <span>Say Hi!</span>
                        <span>Say Hi!</span>
                        <span>Say Hi!</span>
                        <span>Say Hi!</span>
                        </div>
                    </div>
                </a>
            </section>
        </StrictMode>
    </>)
}

export default Navigation;