import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Transition from "../../common-comp/Transition";
import Form from "../../common-comp/form";
import Footer from "../../common-comp/footer";
import threeOne from "../../img/chimera/1-1.png";
import threeTwo from "../../img/chimera/1-2.png";
import fourOne from "../../img/chimera/4-1.png";
import fourTwo from "../../img/chimera/4-2.png";

export default function Gastone() {
  const lenis = window.lenis;

  useEffect(() => {
    lenis.scrollTo("top");
    TransitionIn();
  });

  window.lenis.scrollTo((0, 0), { immediate: true });

  function TransitionIn() {
    var elem = document.getElementById("transIn");
    elem.classList.add("animateTransition-in");
  }

  const history = useNavigate();

  function delayAndGo(e, path) {
    e.preventDefault();
    var elem = document.getElementById("transOut");
    elem.classList.add("animateTransition");
    setTimeout(() => {
      history(path);
      elem.classList.remove("animateTransition");
    }, 1500);
  }

  return (
    <>
      <Transition />
      <div className="works-desc-wp mt-70" id="top">
        <div className="rel">
          <p className="work-p">Chimera Sleepwear</p>
          <p className="work-subtitle mt-20">
            UX Design / Web Design / Graphic Design
          </p>
          <p className="work-subtitle mt-70">2023</p>
          <div className="work-cta-container mobile-hidden">
            <a className="button-outline button--atlas-outline" id="footer-cta" href="https://chimerasleepwear.com/" target="BLANK">
              <span>Visit Website</span>
              <div className="marqueebutton-outline" aria-hidden="true">
                <div className="marquee__inner-outline">
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>

                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div>
          <p className="work-p">
            Chimera Sleepwear, a luxury pajama brand by designer Domizia Vanni,
            needed an online store that mirrored the elegance and versatility of
            their products. I designed the graphic design of the website while
            Francesco Bianciardi was the wordpress responsible. The website
            design emphasizes the unique style of the sleepwear, making it clear
            that these pieces can be worn both day and night. The result is an
            online presence that exudes sophistication and comfort.
          </p>
        </div>
        <div className="rel desk-hidden">
            <a className="button-outline button--atlas-outline" id="footer-cta" href="https://chimerasleepwear.com/" target="BLANK">
              <span>Visit Website</span>
              <div className="marqueebutton-outline" aria-hidden="true">
                <div className="marquee__inner-outline">
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>

                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                </div>
              </div>
            </a>
        </div>
      </div>
      <div className="works-grid mobile-hidden" id="chimera">
        <div className="row" id="first">
          <div className="work-grid-2">
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="row" id="second"></div>
        <div className="row" id="third"></div>
        <div className="row" id="fourth">
          <div className="work-grid-2">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div className="works-mob desk-hidden" id="chimera-mob">
      <div>
          <img src={threeOne} alt="img" />
        </div>
        <div>
          <img src={threeTwo} alt="img" />
        </div>
        <div>
          <img src="/img/chimera/2-mob.png" alt="img" />
        </div>
        <div>
          <img src="/img/chimera/3-mob.png" alt="img" />
        </div>
        <div>
          <img src={fourOne} alt="img" />
        </div>
        <div>
          <img src={fourTwo} alt="img" />
        </div>
      </div>
      <div className="works-desc-wp next-wp">
        <div className="prev-next-style">
          <Link
            to="/works/vanni"
            onClick={(e) => delayAndGo(e, "/works/percorsisomatici")}
          >
            <p className="cta-page-switch">
              <button>Prev</button>
            </p>
          </Link>
          <img src="/img/previous.svg" alt="previous" />
        </div>
        <div className="prev-next-style">
          <Link
            to="/works/Bisteccamore"
            onClick={(e) => delayAndGo(e, "/works/gastone")}
          >
            <p className="cta-page-switch">
              <button>Next</button>
            </p>
          </Link>
          <img src="/img/next.svg" alt="next" />
        </div>
      </div>
      <div className="r-5 mt-120">
        <div className="text-wrapper">
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
          <h1>
            <span className="circle"></span>Logo Design
          </h1>
          <h1>
            <span className="circle"></span>Web Design
          </h1>
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
          <h1>
            <span className="circle"></span>Logo Design
          </h1>

          <h1>
            <span className="circle"></span>Web Design
          </h1>
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
          <h1>
            <span className="circle"></span>Logo Design
          </h1>
          <h1>
            <span className="circle"></span>Web Design
          </h1>
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
        </div>
      </div>
      <Form />
      <Footer />
    </>
  );
}
