import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Transition from "../../common-comp/Transition";
import Form from "../../common-comp/form";
import Footer from "../../common-comp/footer";
import twoOne from "../../img/mancinelli/2-1.png";
import twoTwo from "../../img/mancinelli/2-2.png";
import fourOne from "../../img/mancinelli/4-1.png";
import fourTwo from "../../img/mancinelli/4-2.png";

export default function Mancinelli() {
  const lenis = window.lenis;

  useEffect(() => {
    lenis.scrollTo("top");
    TransitionIn();
  });

  window.lenis.scrollTo((0, 0), { immediate: true });

  function TransitionIn() {
    var elem = document.getElementById("transIn");
    elem.classList.add("animateTransition-in");
  }

  const history = useNavigate();

  function delayAndGo(e, path) {
    e.preventDefault();
    var elem = document.getElementById("transOut");
    elem.classList.add("animateTransition");
    setTimeout(() => {
      history(path);
      elem.classList.remove("animateTransition");
    }, 1500);
  }

  return (
    <>
      <Transition />
      <div className="works-desc-wp mt-70" id="top">
        <div className="rel">
          <p className="work-p">Fabrizio Mancinelli</p>
          <p className="work-subtitle mt-20">Web Design</p>
          <p className="work-subtitle mt-70">2023</p>
          <div className="work-cta-container mobile-hidden">
            <a className="button-outline button--atlas-outline" id="footer-cta" href="https://fabriziomancinelli.us/" target="BLANK">
              <span>Visit Website</span>
              <div className="marqueebutton-outline" aria-hidden="true">
                <div className="marquee__inner-outline">
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>

                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div>
          <p className="work-p">
            For Fabrizio Mancinelli, an Italian film composer based in Los
            Angeles, I developed a website that combines elegance with a
            captivating user experience, as the client requested. The design
            features a dark background, which enhances the sophistication and
            visual appeal of the site. The layout is sleek and intuitive,
            facilitating easy navigation and engagement with his extensive body
            of work.
          </p>
        </div>
        <div className="rel desk-hidden">
            <a className="button-outline button--atlas-outline" id="footer-cta" href="https://fabriziomancinelli.us/" target="BLANK">
              <span>Visit Website</span>
              <div className="marqueebutton-outline" aria-hidden="true">
                <div className="marquee__inner-outline">
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>

                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                  <span>Visit Website</span>
                </div>
              </div>
            </a>
        </div>
      </div>
      <div className="works-grid mobile-hidden" id="mancinelli">
        <div className="row" id="first"></div>
        <div className="row" id="second">
          <div className="work-grid-2">
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="row-sm" id="third"></div>
        <div className="row" id="fourth">
          <div className="work-grid-2">
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="row" id="fifth"></div>
      </div>
      <div className="works-mob desk-hidden" id="guia-mob">
          <div>
            <img src="/img/mancinelli/1-mob.png" alt="img" />
          </div>
          <div>
            <img src={twoOne} alt="img" />
          </div>
          <div>
            <img src={twoTwo} alt="img" />
          </div>
          <div>
            <img src="/img/mancinelli/3-mob.png" alt="img" />
          </div>
          <div>
            <img src={fourOne} alt="img" />
          </div>
          <div>
            <img src={fourTwo} alt="img" />
          </div>
          <div>
            <img src="/img/mancinelli/5-mob.png" alt="img" />
          </div>
      </div>
      <div className="works-desc-wp next-wp">
        <div className="prev-next-style">
          <Link
            to="/works/vanni"
            onClick={(e) => delayAndGo(e, "/works/guianerli")}
          >
            <p className="cta-page-switch">
              <button>Prev</button>
            </p>
          </Link>
          <img src="/img/previous.svg" alt="previous" />
        </div>
        <div className="prev-next-style">
          <Link
            to="/works/Bisteccamore"
            onClick={(e) => delayAndGo(e, "/works/alexandra")}
          >
            <p className="cta-page-switch">
              <button>Next</button>
            </p>
          </Link>
          <img src="/img/next.svg" alt="next" />
        </div>
      </div>
      <div className="r-5 mt-120">
        <div className="text-wrapper">
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
          <h1>
            <span className="circle"></span>Logo Design
          </h1>
          <h1>
            <span className="circle"></span>Web Design
          </h1>
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
          <h1>
            <span className="circle"></span>Logo Design
          </h1>

          <h1>
            <span className="circle"></span>Web Design
          </h1>
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
          <h1>
            <span className="circle"></span>Logo Design
          </h1>
          <h1>
            <span className="circle"></span>Web Design
          </h1>
          <h1>
            <span className="circle"></span>Graphic Design
          </h1>
        </div>
      </div>
      <Form />
      <Footer />
    </>
  );
}
