import {useParallax } from "react-scroll-parallax";
import Form from '../common-comp/form';
import Footer from '../common-comp/footer'
import { StrictMode, useEffect } from "react";
import Transition from "../common-comp/Transition";

const About = () => {

    function followImage(){
        var scrollingDiv = document.getElementById("scrollingDiv")
        var image = document.getElementById("imgScroll")
        var scrollTop = window.scrollY

        // TODO 
        // ERRORE NON SO PERCHE'
        var divTop = scrollingDiv.offsetTop
        // TODO

        var divHeight = scrollingDiv.offsetHeight
        var imageHeight = image.offsetHeight
        if(scrollTop + 40 > divTop && scrollTop + 40 < divTop + divHeight - imageHeight){
            image.style.top = scrollTop - divTop + 40 + "px"
        }else if (scrollTop + 40 <= divTop){
            image.style.top = "0"
        }else{
            image.style.top = divHeight - image.offsetHeight + "px"
        }
    }
    
    const lenis = window.lenis

    useEffect(() => {
        lenis.scrollTo('top')
        TransitionIn()
        window.addEventListener('scroll', followImage);

        //cleanup
        return () => {
            window.removeEventListener('scroll', followImage);
        };
    })

    window.lenis.scrollTo((0,0), {immediate: true})


    const parallax = useParallax({
        easing: 'easeInOut',
        shouldAlwaysCompleteAnimation: true,
        speed: 4,
        scale: [1, .9]
    });

    function TransitionIn(){
        var elem = document.getElementById("transIn");
        elem.classList.add("animateTransition-in")
    }

    return (
        <>  
            <StrictMode>
                <Transition />
                <section className="about-page" id="top">
                    <section className="about-wp">
                        <div className="title-about-wp mt-20">
                            <h1 className="about-title" id="title">about <br /><span>me</span></h1>
                            <div className="personal-img-wp" id="scrollingDiv">
                                <div className='personal-img def-border-radius' id="imgScroll">
                                    <img src={'./img/Laura-Lavorini.png'} alt="Laura Lavorini" className="img-sizes" ref={parallax.ref}/>
                                </div>
                            </div>
                        </div>
                        <div className="p-data-wp mt-50">
                            <div className="title-cv-wp b-bottom">
                                <h2 className="title-cv pd-60-bot ">bio</h2>
                            </div>
                            <div className="parag-cv-wp pd-60-bot b-bottom">
                                <div className="text-anim-wp mobile-hidden">
                                    <p className="parag-cv text-anim d-100">With over 8 years of experience in the design</p>
                                </div>
                                <div className="text-anim-wp mobile-hidden">
                                    <p className="parag-cv text-anim d-200">industry, I have had the opportunity to</p>
                                </div>
                                <div className="text-anim-wp mobile-hidden">
                                    <p className="parag-cv text-anim d-300">collaborate with a diverse range of clients,</p>
                                </div>
                                <div className="text-anim-wp mobile-hidden">
                                    <p className="parag-cv text-anim d-400">tailoring each project to meet their unique</p>
                                </div>
                                <div className="text-anim-wp mobile-hidden">
                                    <p className="parag-cv text-anim d-500">My passion for creating visually</p>
                                </div>
                                <div className="text-anim-wp mobile-hidden">
                                    <p className="parag-cv text-anim d-600">appealing designs was sparked during my</p>
                                </div>
                                <div className="text-anim-wp mobile-hidden">
                                    <p className="parag-cv text-anim d-700">formative years. I am currently also a Web</p>
                                </div>
                                <div className="text-anim-wp mobile-hidden">
                                    <p className="parag-cv text-anim d-800">Graphic Design course supervisor at Nemo</p>
                                </div>
                                <div className="text-anim-wp mobile-hidden">
                                    <p className="parag-cv text-anim d-900">Academy of Digital Arts in Florence.</p>
                                </div>
                                <p className="parag-cv desk-hidden">
                                    With over 8 years of experience in the design industry, I have had the opportunity to
                                    collaborate with a diverse range of clients, tailoring each project to meet their unique goals.
                                    My passion for creating visually appealing designs was sparked during my formative years. I
                                    am currently also a Web Graphic Design course supervisor at Nemo Academy of Digital Arts
                                    in Florence.
                                </p>
                                <div className="work-pos-wp">
                                    <p className="work-pos-p">
                                        I hold a degree in painting and sculpture, which has deeply influenced my approach to
                                        graphic and web design. This classical art background allows me to infuse my work with a
                                        rich sense of aesthetics and creativity.
                                    </p>
                                    <p className="work-pos-p">
                                        My expertise spans across various domains, including Graphic Design, Digital Branding, and
                                        Web Design. I am dedicated to pushing the boundaries of design, exploring new techniques
                                        and trends to deliver exceptional results.
                                    </p>
                                </div>
                            </div>

                            <div className="title-cv-wp pd-60-y b-bottom">
                                <hr className="desk-hidden"/>
                                <h2 className="title-cv ">Curriculum</h2>
                            </div>
                            <div className="parag-cv-wp cv-grid pd-60-y b-bottom">
                                <p className="cv-job">2023 - present</p>
                                <p className="cv-job">Supervisor Web Graphic Design’s Course at Nemo Academy of Digital Arts</p>

                                <p className="cv-job mt-20">2020 - present</p>
                                <p className="cv-job mt-20">Web Graphic Design Teacher at Nemo Academy of Digital Arts</p>

                                <p className="cv-job mt-20">2019 - 2023</p>
                                <p className="cv-job mt-20">Founder of Ragou Design web design studio in Florence</p>

                                <p className="cv-job mt-20">2016 - present</p>
                                <p className="cv-job mt-20">Graphic Designer Freelance at Marte Comunicazione</p>

                                <p className="cv-job mt-20">2016 - present</p>
                                <p className="cv-job mt-20">Graphic Designer Freelance</p>

                                <p className="cv-job mt-20">2016 - 2017</p>
                                <p className="cv-job mt-20">Graphic Designer at Eliograf Prato</p>
                            </div>

                            <div className="title-cv-wp pd-60-y b-bottom">
                                <hr className="desk-hidden"/>
                                <h2 className="title-cv">Services</h2>
                            </div>
                            <div className="parag-cv-wp services-grid pd-60-y b-bottom">
                                <p className="cv-job">Web Design</p>
                                <p className="cv-job">Logo Design</p>
                                <p className="cv-job">Illustration</p>

                                <p className="cv-job mt-20">Graphic Design</p>
                                <p className="cv-job mt-20">Branding</p>
                                <p className="cv-job mt-20">UX Design</p>
                            </div>
                            
                            <div className="title-cv-wp pd-60-y">
                                <hr className="desk-hidden"/>
                                <h2 className="title-cv">Testimonials</h2>
                            </div>
                            <div className="parag-cv-wp pd-60-y">
                                <p className="parag-testimonial">
                                    “We have been collaborating with Laura for over eight years and we are very satisfied with the results achieved. We have worked together on many different kind of projects, always managing to meet the client's requirements in terms of style and authenticity. Pragmatic and determined, she possesses rare competence and relational skills. Sometimes it seems like she can read the client's mind.”                           
                                </p>      
                                <div className="review">
                                    <img src="/img/aboutReview/nicco.png" alt="Niccolò Tempestini" />
                                    <p>Niccolò Tempestini, CEO at Marte Comunicazione, Prato</p>
                                </div>
                                <hr className="desk-hidden"/>

                                <p className="parag-testimonial mt-50">
                                    “Laura's professional competence is based on a great ability to listen and the uncommon skill of translating objectives into original graphic projects. Her consultative approach is extremely helpful in focusing on the distinctive messages to be communicated. Her uncommon transversal competence allows her to manage complex integrated communication projects. In the long-term collaboration with my company (active in advanced B2B services), Laura has helped and supported us in every phase and activity of external communication: from the study of coordinated image, to the creation of the website, to the graphic realization of targeted communication on social networks, the creation of infographics, and the creation and realization of cartonboard supports for live events. For this reason, my experience of professional collaboration with Laura has been highly satisfying.”                                </p>
                                <div className="review">
                                    <img src="/img/aboutReview/cacciari.png" alt="Luca Cacciari" />
                                    <p>Gian Luca Cacciari, CEO and founder Sviluppo Umano srl, Florence</p>
                                </div>
                                <hr className="desk-hidden"/>

                                <p className="parag-testimonial mt-50">“As a client of Laura, I have had the opportunity to appreciate her attentiveness in listening to my requests and the speed of execution of various tasks that I gave her. I have also greatly appreciated the creative and stylistic aspect of the clean and concise design, which resonates with me. Today, Laura is not only one of the teachers at our Academy, but she also designs and develops graphics and websites for the companies where I work.“</p>
                                <div className="review">
                                    <img src="/img/aboutReview/luca.png" alt="Luca Chiarotti" />
                                    <p>Luca Chiarotti, Art director and founder Nemo Academy Of Digital Arts, Florence</p>
                                </div>
                                <hr className="desk-hidden"/>

                                <p className="parag-testimonial mt-50">“Laura understood my needs and surprised me with more I could ever hope for. Graphically captivating in its simple structure the website that Laura created for me is truly something special, so much that I received a lot of positive feedback from colleagues and clients. I couldn’t think of anyone better in her field. Passionate, knowledgeable and patient, Laura is, despite her young age, a consummate professional who will surprise and please even the pickiest client! “</p>
                                <div className="review">
                                    <img src="/img/aboutReview/mancinellif.png" alt="Fabrizio Mancinelli" />
                                    <p>Fabrizio Mancinelli, composer, Los Angeles</p>
                                </div>
                                <hr className="desk-hidden"/>
                            </div>
                        </div>
                    </section>
                    <div className="r-5">
                        <div className="text-wrapper mtb-200">
                            <h1><span className="circle"></span>Graphic Design</h1>
                            <h1><span className="circle"></span>Web Design</h1>
                            <h1><span className="circle"></span>Illustration</h1>
                            <h1><span className="circle"></span>Logo Design</h1>
                            <h1><span className="circle"></span>Branding</h1>
                            <h1><span className="circle"></span>UX Design</h1>

                            <h1><span className="circle"></span>Graphic Design</h1>
                            <h1><span className="circle"></span>Web Design</h1>
                            <h1><span className="circle"></span>Illustration</h1>
                            <h1><span className="circle"></span>Logo Design</h1>
                            <h1><span className="circle"></span>Branding</h1>
                            <h1><span className="circle"></span>UX Design</h1>
                        </div>
                    </div>
                </section>
                <Form></Form>
                <Footer></Footer>
            </StrictMode>
        </>
    )
}
export default About;