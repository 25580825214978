import { StrictMode, Suspense } from 'react';
import SingleBaloon from './SingleBaloon'

function Footer(){

    var loaded = 0

    if(window.innerWidth < 1000){
        loaded = 1
    }

    return(
        <StrictMode>
            <footer className='footer-wp' id='footer'>
                <Suspense fallback={null}>
                    <SingleBaloon />
                </Suspense>
                <div className='p-abs-100'>
                    <img src={'/img/copy-footer.svg'} alt="footer" className='img-footer mobile-hidden'/>
                    <img src="/img/footer-img.png" alt="footer-img" className='img-footer desk-hidden'/>
                    <div className="data-footer-wp mt-20">
                        <p className='piva'>P.IVA 02435900978</p>
                        <div className='social'>
                            <p className='ig'><a href="https://www.instagram.com/laura.lavorini/" target="_blank">instagram</a></p>
                            <p className='ln'><a href="https://www.linkedin.com/in/laura-lavorini-8439639b/" target="_blank">linkedin</a></p>
                            <p className='fb'><a href="https://www.facebook.com/lauralavorinigraphicdesigner/" target="_blank">facebook</a></p>
                        </div>
                        {loaded === 0 ? 
                            <><p className='privacy'>developed by Alessio Majid</p></>
                            :
                            <>
                                <p className='devby'>developed by Alessio Majid</p>
                            </>
                        }
                    </div>
                </div>
            </footer>
        </StrictMode>
    )
}

export default Footer;